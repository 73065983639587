@charset "UTF-8";
/*    font-family: 'TTNormsProVarRoman','Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
*/
body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color:white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/*+ ----ens跑马灯开始*/

.ens-latest-order-marquee-container {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
}

.ens-link {
  color:#1890ff;
  font-weight: bold;
  cursor: pointer;
}

.right-align {
  text-align: right;
}

.bold {
  font-weight: bold;
}

/*- -----ens跑马灯结束*/


/*+ ----ens 交易记录开始*/

.ens-order-listview-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.ens-order-listview-item:hover {
  background-color: #e6f7ff;
}

.ens-order-listview-item > i {
  margin-right: 10px;
  font-size: 1.3rem;
}

.ens-order-listview-item-content {
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.2);
  text-align: left;
}

.ens-latest-order-item-reg-tip {
  display: flex;
  flex-direction: column;
  width: 100px;
}
.ens-order-listview-bit-btn {
  
  text-align: left;
}
/*- -----ens 交易记录结束*/



.change-lang-caption {
  margin-top: 4px;
}

.language-list-container {
  width: 120px;
}

.language-item-text {
  margin-right: 20px;
  margin-left: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media(max-width: 800px) {
  .das-table-avatar {
    display: none;
  }
}

.das-account-info-wrapper {
  display: inline-flex;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
}

.das-account-name {
    font-size: 18px;
    font-weight: 600;
    color: #11142d;
    word-break: break-all;
}

.registed-account-name {
  margin-top: 2px;
  font-size: 18px;
  font-weight: 600;
  color: #11142d;
  word-break: break-all;
}

@media (max-width: 480px) {
  .das-account-name {
      font-size: 14px;
      font-weight: 500;
  }

  .registed-account-name {
    font-size: 14px;
    font-weight: 500;
  }
}
.suggest-list-wrapper {
  display:flex;
  flex-wrap: wrap;
  width:100%;
  justify-content: space-around;
  flex-direction:row;
}

.suggest-list-btn {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.hot-accounts-filter-tips {
  font-size: 14px;
  margin-right: 1rem;
  font-weight: 600;
  color: #00E09C;
  word-break: break-all;
}

.das-account-price {
  font-size: 12px;
  font-weight: 500;
  color: #d9d9d9;
  word-break: break-all;
}

.das-account-status-tag {
  height: 22px;
}

.statistic-summary-wrapper {
  display:flex;
  flex-wrap: wrap;
  width:100%;
  justify-content: space-around;
  flex-direction:row;
  background-color: black;
  border-radius: 0.5rem;
}

.statistic-info-item {
  margin: 1rem 1rem 0.2rem;
  width: 130px;
}

.statistic-data-updatetime {
  font-weight: 600;
  text-align: left;
  color: #8f8b8b;
}

.statistic-das-count-title {
  font-weight: 600;
  text-align: left;
  color: #8f8b8b;
  height: 44px;
  padding: 10px 0;
}

.total-das-count {
  font-weight: 600;
  text-align: left;
  color: #8f8b8b;
}

.statistic-owner-count {
  font-size: 40px;
  font-weight: 600;
  font-family: Optima,'Helvetica Neue',Arial,Helvetica,sans-serif;
  text-align: left;
  color: #7B49F6;
}

.statistic-das-count {
  font-size: 40px;
  font-weight: 600;
  font-family: Optima,'Helvetica Neue',Arial,Helvetica,sans-serif;
  text-align: left;
  color: #c8304e;
}

.statistic-change-icon {
  width: 12px;
  height: 14px;
  margin-left: 10px;
  /* margin-right: 3px; */
  margin-top: 15px;
}

.statistic-item-with-growth {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.statistic-growth_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.statistic-change-up-value {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  margin-top: 15px;
  color: #3DC489;
}

.statistic-change-down-value {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  margin-top: 15px;
  color: #FF5F65;
}

.statistic-overview_vert-separate {
  width: 1px;
  background: #8f8b8b;
  margin: 10px 1px;
}


/*
.statistic-daily-container {
  -webkit-box-pack: justify;
  display: flex;
  justify-content:space-around;
  flex-direction: row;
  width: 100%;
  padding: 0px;
  gap: 1em;
}

@media screen and (min-width: 852px) {
  .statistic-daily-container {
    flex-direction: row;
  }
}

.statistic-daily-chart {
  border-radius: 12px;
  color: rgb(60, 58, 75);
  border-style:solid;
  border-width: 0px;
  min-width: 410px;
  overflow: hidden;
  position: relative;
  padding: 1rem;
}

.row-layout {
  display: flex;
  flex-direction: column;
  
  width: 100%;
}
*/

.das-wordcloud-wrapper {
  max-width: 500px;
  width: 500px;
}

.group-radio {
  margin-top: 2px;
  font-size: 18px;
  font-weight: 600;
  color: #11142d;
  word-break: break-all;
}

.bold_pink {
  margin-left: 10px;
  margin-top: 3px;
  font-size: 16px;
  font-weight: 400;
  color: #dd09c1;
  vertical-align: middle;
}

.bannerWraper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-transform: rotate(0deg);
    border-radius: .4rem;
    margin-bottom: 10px;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex;
  grid-gap: 0.5rem;
  cursor: pointer;
}

.bannerImage {
  border-radius: 0.5rem;
}

/*
.dasAnimation {
    width: 100wh;
    color: #fff;
    padding: 10px;
    background: linear-gradient(-45deg, #eb9e87, #E73C7E, #23A6D5, #2e3332);
    background-size: 400% 400%;
    -webkit-animation: Gradient 15s ease infinite;
    -moz-animation: Gradient 15s ease infinite;
    animation: Gradient 15s ease infinite;
}*/

.dasAnimation {
  width: 100wh;
  color: #fff;
  padding: 10px;
  background: #262626;
  background-size: 400% 400%;
}

/*
display: -ms-flexbox;
display: flex;
*/
.content {
    max-width: 1000px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: auto;
}


.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  margin-left: -1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 0px 16px 0px;
  overflow-wrap: break-word;
}
/*
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #ff00f0;
  transition: background 0.3s;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #ffbbdd;
}

.ant-tag {
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  color: rgba(0, 0, 0, 0.85);
}

.ant-pagination-item-active {
  font-weight: 600;
  background: #a21f1f;
  border-color: #a21f1f;
}

.ant-pagination-item {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 32px;
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-next,
.ant-pagination-prev {
  color: rgba(0, 0, 0, 0.85);
  font-family: inherit;
  border-radius: 32px;
}

.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 32px;
}
*/
text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 10px;
  font-weight: 600;
}

/*
.ant-alert-message {
  color: rgba(0, 0, 0, 0.85);
  font-size: larger;
  font-family: TTNormsProVarRoman;
  font-weight: 450;
}
*/
/*
a {
  text-decoration: none;
  color: rgb(24, 144, 255);
  font-size: normal;
  font-family: TTNormsProVarRoman,sans-serif;
  font-weight: bold;
}

.ant-btn-round {
  height: 32px;
  padding: 1px 16px;
  border-radius: 3px;
  font-size: large;
  font-family: TTNormsProVarRoman,sans-serif;
}
*/
/*
td .ant-table-cell {
  position: relative;
  padding: 16px 0px;
  overflow-wrap: break-word;
}*/

/*
body, button, input, select, textarea {
  font-family: TTNormsProVarRoman, "Microsoft Yahei", Tahoma, Helvetica, Arial, sans-serif !important;
  font-size: 14px;
  font-weight: bold;
}
*/


/*
@-webkit-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}

@-moz-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}

@keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}
*/

/*
Footer
*/
/*
.site-footer
{
  background-color:#26272b;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#737373;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#737373;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#737373
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{ 
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}
*/


@media screen and (prefers-reduced-motion:reduce) {
    * {
        transition: .01s!important
    }
}

*,:after,:before {
    box-sizing: border-box
}

a {
    text-decoration: none
}

div {
    display: block;
}

html {
    font-family: Blanco,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
    font-size: clamp(12px,calc(.8rem + .25vw),20px);
    font-weight: 400;
    line-height: 1.7;
    --gap: 2rem;
}


html {
    --maxWidth: 1600px;
    --gap: 3rem;
    scrollbar-color: #666 #201c29;
    color: #fff;
    overflow-x: hidden;
    --featured-img: linear-gradient(180deg,#fff,#262626);
    --bg-blend-mode: overlay;
    background-color: #262626;
    background-position: top 58px center;
    background-repeat: no-repeat;
    background-image: radial-gradient(50% 50% at top center,rgba(0,0,0,.66),#262626),linear-gradient(180deg,#fff,#262626);
    background-image: radial-gradient(50% 50% at top center,rgba(0,0,0,.66),#262626),var(--featured-img);
    background-size: 120% 2000px,100% 2000px
}

@media(max-width: 1200px) {
    html {
        font-family: Blanco,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
        font-size: clamp(12px,calc(.8rem + .25vw),20px);
        font-weight: 400;
        line-height: 1.7;
        --gap: 2rem;
    }
}

@media(max-width: 800px) {
    html {
        font-family: Blanco,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
        font-size: clamp(12px,calc(.8rem + .25vw),20px);
        font-weight: 400;
        line-height: 1.7;
        --gap:1rem;
    }
}
/*
html {
    --gap: 2rem;
}
*/

body,dd,dl,html,menu,ol,ul {
    margin: 0
}

menu,ol,ul {
    padding: 0
}

.h4,h4 {
    font-family: MD Primer Bold,Rubik,Lato,Lucida Grande,Lucida Sans Unicode,Tahoma,Sans-Serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    margin: 0 0 .8rem
}

.h5,h5 {
    font-weight: 800;
    margin: 0 0 .5rem
}

.h5,h5,h6 {
    font-size: 1.3rem
}

h6 {
    font-weight: 400
}

.h4,.h5,.h6,h4,h5,h6 {
    line-height: 1.05
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
/* ----begin 注册商选择，style from da.system */


.registry-elem {
  display: flex;
  height: 44px;
  padding-right: 0px;
  padding-left: 10px;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  background-color:#1890ff;
  border-color: #e8eef4;
  border-radius: 6px;
  transition: background-color 200ms ease;
  color: #000;
  text-decoration: none;
  cursor: pointer
}
/* 去掉hover效果
.registry-elem:hover {
  background-color: #e8eef4
}
*/

.registry-elem-inner {
  max-width: 100%;
  padding-top: 8px;
}

.image-5 {
  padding-right: 0px;
  height:24px;
}

.select-registry-btn {
  font-size: 14px;
  text-align: center;
  color: #fff;
}

.select-registry-tips {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-right: 0.2rem;
  text-align: right;
  color: #8f8b8b;
}

/* 选择、注册账号按钮样式开始 */
.dasla-register-container {
  display: inline-flex;
  grid-gap: 12px;
  gap: 12px;
}

@media(max-width: 480px) {
  .dasla-register-container {
    display: inline-flex;
    flex-wrap: wrap;
    grid-gap: 0px;
    gap: 0px;
  }
}

.dasla-btn-register-wraper {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.dasla-btn-select-account {
  color: #000;
  background: #FFD814;
  border-color: #FCD200;
  font-weight: bold;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.dasla-btn-register-account {
  color: #000;
  background: #FFA41C;
  border-color: #FF8F00;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.dasla-btn-select-account > a:only-child {
  color: currentColor;
}
.dasla-btn-select-account > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.dasla-btn-select-account:hover,
.dasla-btn-select-account:focus {
  color: #000;
  background: #F7CA00;
  border-color: #F2C200;
  box-shadow: 0 2px 5px 0 rgba(213,217,217,.5);
}

.dasla-btn-register-account:hover,
.dasla-btn-register-account:focus {
  color: #000;
  background: #FA8900;
  border-color: #E3931E;
  box-shadow: 0 2px 5px 0 rgba(213,217,217,.5);
}

.dasla-btn-select-account:hover > a:only-child,
.dasla-btn-select-account:focus > a:only-child {
  color: currentColor;
}
.dasla-btn-select-account:hover > a:only-child::after,
.dasla-btn-select-account:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.dasla-btn-select-account:active {
  background: #FFF;
  border-color: #FFD814;
  box-shadow: 0 2px 5px 0 rgba(213,217,217,.5);
}
.dasla-btn-register-account:active {
  background: #FFF;
  border-color: #FFA41C;
  box-shadow: 0 2px 5px 0 rgba(213,217,217,.5);
}

.dasla-btn-select-account:active > a:only-child {
  color: currentColor;
}
.dasla-btn-select-account:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.dasla-btn-select-account[disabled],
.dasla-btn-select-account[disabled]:hover,
.dasla-btn-select-account[disabled]:focus,
.dasla-btn-select-account[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.dasla-btn-select-account[disabled] > a:only-child,
.dasla-btn-select-account[disabled]:hover > a:only-child,
.dasla-btn-select-account[disabled]:focus > a:only-child,
.dasla-btn-select-account[disabled]:active > a:only-child {
  color: currentColor;
}
.dasla-btn-select-account[disabled] > a:only-child::after,
.dasla-btn-select-account[disabled]:hover > a:only-child::after,
.dasla-btn-select-account[disabled]:focus > a:only-child::after,
.dasla-btn-select-account[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
/* 选择账号按钮样式结束 */

/* 改样式已被弃用*/
.dasla-button {
  font-family: MD Primer Bold,Rubik,Lato,Lucida Grande,Lucida Sans Unicode,Tahoma,Sans-Serif;
  font-style: normal;
  background-color: #5e5e5e!important;
  color: #fff!important;
  border: 0;
  border-bottom: 1px solid #262626;
  border-right: 1px solid #262626;
  box-shadow: inset 1px 1px 0 #777,0 2px 3px rgba(0,0,0,.4);
  display: inline-block;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
  text-decoration: none!important;
  border-radius: 4px!important;
  white-space: nowrap;
  padding: 10px 10px;
  text-align: center;
  transition: .07s;
  position: relative;
}

/* ----end 注册商选择，style from da.system */

/*---- start, 排行榜开始 -------*/
.leaderboards-grid {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.leader-board-wrapper {
  padding: 1.5rem;
  border-radius: 16px;
  /*background: linear-gradient(85deg,#434343,#262626);*/
  background: linear-gradient(85deg,#434343,#565656);
  color: #fff;
  display: flex;
  flex-direction: column;
  transition: .2s;
  margin: 0;
  margin: 20px 0px;
  width: 100%;
}

.leader-board-title {
  text-align: center;
  font-size: 24px;
  color:#22C493;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.leader-board-item-row {
  display: grid;
  grid-template-columns: 18px 32px 1fr 70px;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  color: #565656;
  line-height: 1.3;
  padding: 0.5rem;
  margin: 0.5rem 0;
  background: #dedede;
  border-color: #dedede;
  border-radius: 1rem;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
}


.leader-board-item-row:hover,
.leader-board-item-row:focus {
  color: #000;
  background: #fcfbfa;
  border-color: #676663;
  box-shadow: 0 2px 5px 0 rgba(213,217,217,.5);
  cursor: pointer;
}

.leader-board-item-name {
  color: rgba(0, 0, 0, 0.6); 
  width: 100%;
  text-shadow: 2px 2px 3px rgba(255,255,255,0.2);
  font-size: 1.3rem;
  text-align: left;
  font-weight: 500;
}

.leader-board-rank-index {
  color: rgba(0, 0, 0, 0.6); 
  width: 100%;
  text-shadow: 2px 2px 3px rgba(255,255,255,0.2);
  font-size:1.0rem;
  text-align: center;
  font-weight:500;
}

.leader-board-rank-index-top3 {
  color: rgba(0, 0, 0, 0.99);
  width: 100%;
  text-shadow: 0px -1px 22px #20a2ff;
  font-weight: bold;
  font-size: 2.0rem;
  z-index: 1000;
  text-align: left;
  margin-top: -12px;
  margin-left: -6px;
}

.leader-board-rank-value {
  color: #171614;
  width: 100%;
  text-shadow: 2px 2px 3px rgba(255,255,255,0.2);
  font-size:1.1rem;
  text-align: right;
  font-weight:500;
}

.gold {
  background-color: #E9AE02;
}

.silver {
  background-color: #AFAFAF;
}

.bronze {
  background-color: #BB771D;
}

.leader-board-top3 {
  font-size: 2rem;
  z-index: 1000;
  text-align: left;
}

@media (min-width: 1000px) {
  .leader-board-wrapper  {
      width: 480px;
  }
}

/* ----end 排行榜结束 */

/*---- start ，卡片样式开始 -------------*/

.popular-das {
	box-sizing: content-box;
	display: flex;
	margin: 0 0 3rem;
	margin: 0 0 var(--gap);
	position: relative
}

@media(min-width:1200px) {
	.popular-das:after {
		content: "";
		position: absolute;
		right: 0;
		top: 3rem;
		bottom: 3.6rem;
		width: 5px;
		border-radius: 5px;
		background: linear-gradient(130deg,#ff7a18,#af002d 41.07%,#319197 76.05%);
		box-shadow: -10px 0 20px 3px #000
	}
}

@media(max-width:1200px) {
	.popular-das {
		scrollbar-color: #666 #201c29;
		padding-bottom: 3rem;
		padding-bottom: var(--gap);
		padding-left: 5px;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
		-ms-scroll-snap-points-x: repeat(250px);
		scroll-snap-points-x: repeat(250px);
		-ms-scroll-snap-type: mandatory;
		scroll-snap-type: mandatory;
		-ms-scroll-snap-type: x mandatory;
		scroll-snap-type: x mandatory;
		-webkit-scroll-snap-type: x mandatory
	}

	.popular-das::-webkit-scrollbar {
		width: 10px;
		height: 10px
	}

	.popular-das::-webkit-scrollbar-thumb {
		background: #434343;
		border-radius: 10px;
		box-shadow: inset 2px 2px 2px hsla(0,0%,100%,.25),inset -2px -2px 2px rgba(0,0,0,.25)
	}

	.popular-das::-webkit-scrollbar-track {
		background: linear-gradient(90deg,#434343,#434343 1px,#262626 0,#262626)
	}

	.popular-das .mini-card {
		scroll-snap-align: start
	}
}

.header-card-title {
  color: white;
}
.popular-header {
	margin-top: 4rem;
	flex: 0 0 65px;
	margin-right: 1rem;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 1.5rem;
	-webkit-transform: translateY(-10px);
	        transform: translateY(-10px)
}

@media(max-width:1200px) {
	.popular-header {
		scroll-snap-align: start;
		scroll-margin-inline-start: 5px
	}
}

.popular-header .edit-popular {
	position: absolute;
	top: 5px;
	right: 5px
}

.popular-header p {
	margin: 0
}

.popular-header:before {
	content: "";
	/*background: linear-gradient(130deg,#ff7a18,#af002d 41.07%,#319197 76.05%);*/
  background: linear-gradient(130deg,#009CFF,#07BF8B 41.07%,#C123FF 76.05%);
	position: absolute;
	top: -5px;
	left: -5px;
	width: calc(100% + 10px);
	height: calc(100% + 10px);
	z-index: 1;
	border-radius: 12px
}

.popular-header:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	z-index: 2;
	border-radius: 8px
}

.popular-header>* {
	position: relative;
	z-index: 3
}

@media(max-width:800px) {
	.popular-header {
		flex: 0 0 50px
	}
}

.mini-card-grid {
	display: grid;
	grid-gap: 1rem;
	gap: 1rem;
	grid-template-columns: repeat(auto-fill,minmax(250px,1fr));
	margin: 0 0 1.5rem;
	position: relative
}

.mini-card-grid {
	scrollbar-color: #666 #201c29;
	scrollbar-gutter: always;
	padding: 2rem 0 0rem 1rem;
	margin: 0;
	display: flex;
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch
}

.mini-card-grid::-webkit-scrollbar {
	width: 10px;
	height: 10px
}

.mini-card-grid::-webkit-scrollbar-thumb {
	background: #434343;
	border-radius: 10px;
	box-shadow: inset 2px 2px 2px hsla(0,0%,100%,.25),inset -2px -2px 2px rgba(0,0,0,.25)
}

.mini-card-grid::-webkit-scrollbar-track {
	background: linear-gradient(90deg,#434343,#434343 1px,#262626 0,#262626)
}

@media(max-width:1200px) {
	.mini-card-grid {
		overflow-x: visible
	}
}

.mini-card {
	padding: 1.5rem;
	border-radius: 16px;
	background: linear-gradient(85deg,#434343,#262626);
	color: #fff;
	display: flex;
	flex-direction: column;
	transition: .2s;
	margin: 0
}

.mini-card a {
	text-decoration: none
}

.mini-card h3 a {
	color: #fff!important
}

.mini-card h3 .aal_anchor {
	display: none
}

.mini-card .tags {
	margin: 0 0 .5rem
}

.mini-card .author-name {
	color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.mini-card time {
	display: block;
	margin: 0 0 .25rem;
	color: #777;
}

.mini-card time strong {
	color: #d4d4d4
}

.mini-card {
	min-width: 260px;
	min-height: 350px;
	box-shadow: -2rem 0 3rem -2rem #000
}

.mini-card:focus-within, .mini-card:hover {
	-webkit-transform: translateY(-1rem) rotate(3deg);
	        transform: translateY(-1rem) rotate(3deg)
}

.mini-card:focus-within~.mini-card,.mini-card:hover~.mini-card {
	-webkit-transform: translateX(30px);
	        transform: translateX(30px)
}

.mini-card:first-child:focus-within,.mini-card:first-child:hover {
	-webkit-transform: translate(-.5rem,-1rem) rotate(3deg);
	        transform: translate(-.5rem,-1rem) rotate(3deg)
}

.mini-card:not(:first-child) {
	margin-left: -30px;
	box-shadow: -3rem 0 3rem -2rem #000
}

@media(max-width:1200px) {
	.mini-card {
		min-width: 260px
	}

	.mini-card:not(:first-child) {
		margin-left: -30px
	}

	.mini-card:hover {
		-webkit-transform: translateY(-1rem);
		        transform: translateY(-1rem)
	}

	.mini-card:hover~.mini-card {
		-webkit-transform: translateX(30px);
		        transform: translateX(30px)
	}
}

@media(max-width:800px) {
	.mini-card {
		min-width: 260px
	}

	.mini-card:not(:first-child) {
		margin-left: -10px
	}

	.mini-card:hover {
		-webkit-transform: translateY(-1rem);
		        transform: translateY(-1rem)
	}

	.mini-card:hover~.mini-card {
		-webkit-transform: translateX(10px);
		        transform: translateX(10px)
	}
}

.mini-card-price {
	font-size: 1.3rem;
	margin: 0 0 1rem;
  color:rgba(252, 250, 250, 0.85);
  text-shadow: 2px 2px 3px rgba(255,255,255,0.2);
  font-weight: bold;
  text-align: center;
}


.golden-das-name-container {
  margin: 0 0 1rem 0;
  height: 140px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  flex-flow: wrap;
  width: 100%;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
}
/*
.golden-das-name-container {
	margin: 0 0 0 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-flow: wrap;
	width: 100%;
	border-radius: 1rem;
  color: #0085AE;
  box-shadow: 0px 0px 10px black;
}*/


.icon-das-for-sale {
  width: 64px;
  height: 64px;
  background-image: url('https://bestdas.com/images/account/account-share-decoration.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 2rem;
}

.golden-das-bit-fix {
  color: rgba(0, 0, 0, 0.6); 
  width: 100%;
  text-shadow: 2px 2px 3px rgba(255,255,255,0.2);
  font-size:50px;
  text-align: center;
  font-weight:bold;
  line-height: 60px;
}

.golden-das-name {
  color: rgba(255, 255, 255, 0.6); 
  text-shadow: 2px 2px 3px rgba(0,0,0,0.9);
  font-size:50px;
  text-align: center;
  font-weight:bold;
  line-height: 60px;
}


.golden-das-name-white {
  color: rgba(255,255,255,0.6);
  text-shadow: 0 1px 0 #ccc, 
             0 2px 0 #c9c9c9,
             0 3px 0 #bbb,
             0 4px 0 #b9b9b9,
             0 5px 0 #aaa,
             0 6px 1px rgba(0,0,0,.1),
             0 0 5px rgba(0,0,0,.1),
             0 1px 3px rgba(0,0,0,.3),
             0 3px 5px rgba(0,0,0,.2),
             0 5px 10px rgba(0,0,0,.25),
             0 10px 10px rgba(0,0,0,.2),
             0 20px 20px rgba(0,0,0,.15);
  font-size:50px;
  font-weight:bold;
}

/*
.das-golden-name {
    background: linear-gradient(to bottom, #cfc09f 22%, #cfc09f 26%, #cfc09f 27%,#ffecb3 40%,#3a2c0f 78%); 
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
	  font-family: "Poppins", sans-serif;
    z-index: 10;
    position: relative;
	  font-size: 40px;
	  margin: 0;
	  font-weight: 400;
}

.das-golden-name:after {
    background: none;
    content:attr(account-name);
    left: 0;
    top: 0px;
    z-index: -1;
    position: absolute;
    text-shadow: 
		-1px 0 1px #c6bb9f, 
		0 1px 1px #c6bb9f, 
		5px 5px 10px rgba(0, 0, 0, 0.4),
		-5px -5px 10px rgba(0, 0, 0, 0.4);
}
*/
.mini-card-owner-row {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: .5rem;
  gap: .5rem;
  align-items: center;
  color: #565656;
  line-height: 1.3;
  padding-bottom: .5rem;
}

.mini-card-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: .5rem;
}

.mini-card-describle-row {
  display: grid;
  grid-template-columns: 10px auto 10px;
  grid-gap: 8px;
  height: 48px;
  padding-bottom: .5rem;
  margin-bottom: 1rem;
}

.author-row {
  -webkit-margin-before: auto;
  margin-block-start: auto;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: .5rem;
  gap: .5rem;
  align-items: center;
  color: #565656;
  line-height: 1.3;
  padding-top: .5rem;
}


/*---- end ，卡片样式结束 -------------*/

/*---- start ，证书样式结束 -------------*/

.das-certificate-container {
  max-width: 1008px;
}
.das-certificate {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}

/*---- end ，证书样式结束 -------------*/

.site-footer {
    margin: calc(3rem*2) 0 0 0;
    margin: calc(var(--gap)*2) 0 0 0;
    padding: 0 0 3rem;
    padding: 0 0 var(--gap);
    position: relative;
    background-color: #111;
}


.site-footer h4,.site-footer h5,.footer-team {
    font-family: MD Primer Bold,Rubik,Lato,Lucida Grande,Lucida Sans Unicode,Tahoma,Sans-Serif;
    font-style: normal;
    font-weight: 700;
    letter-spacing: .1rem;
    text-transform: uppercase;
    color: #ff7a18;
    margin: 2rem 0 .25rem
}

.site-footer h4 {
    -webkit-margin-before: 0;
    margin-block-start:0
}

.site-footer .icon-logo-star {
    position: absolute;
    width: 120px;
    height: 120px;
    z-index: -1;
    background-image: url("https://oss-cdn1.bihu-static.com/image/20210925/90b4f9d5e2844402b0079dbd51273402_GE2TMKRRGU3A.png");
    top: -90px;
    left: 3rem;
    left: var(--gap)
}


@media(max-width: 830px) {
    .site-footer .icon-logo-star {
        top:-130px;
        left: auto;
        right: 40px
    }
}

@media(max-width: 800px) {
    .site-footer {
        margin-top:3rem;
        
    }
}

.colophon {
    font-size: 1.6rem;
    line-height: 1.3;
    margin: 2rem 1rem 0;
    color:azure;
    font-weight:600;
}

.footer-inside {
    max-width: 1600px;
    max-width: var(--maxWidth);
    margin: 0 auto;
    padding: 0 3rem;
    padding: 0 var(--gap)
}

.footer-message-and-form {
    display: grid;
    grid-template-columns: 1fr 1.4fr;
    grid-gap: 3rem;
    gap: 3rem;
    grid-gap: var(--gap);
    gap: var(--gap);
    align-items: end
}


@media(max-width: 830px) {
    .footer-message-and-form {
        grid-template-columns:1fr
    }

    .footer-message-and-form .footer-form-wrap {
        order: -1
    }

    .footer-message-and-form .footer-form-wrap .footer-form {
        flex-wrap: wrap
    }
}

.footer-form-wrap {
    background: #434343;
    padding: 1.5rem;
    margin: -3rem 0 0;
    position: relative;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px
}


.footer-form-wrap h5 {
    margin-top: 0;
}


.footer-form-wrap:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 100%;
    width: 20px;
    height: 48px;
    background: #909090;
    -webkit-clip-path: polygon(100% 0,100% 100%,0 100%);
    clip-path: polygon(100% 0,100% 100%,0 100%)
}

.link-grid {
    padding-top: 1.5rem;
    margin-left: 1rem;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 1rem;
    gap: 1rem;
    line-height: 1.5
}


@media(max-width: 950px) {
    .link-grid {
        grid-template-columns:repeat(2,1fr)
    }
}


@media(max-width: 374px) {
    .link-grid {
        grid-template-columns:1fr
    }
}


.link-grid li {
    display: flex
}


.link-grid a {
    color: #a9a39f;
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin-right: .2rem;
    font-size: 1.1rem
}


.link-grid a:focus,.link-grid a:hover {
    color: #fff;
    opacity: 1
}

.link-grid span {
    opacity: 1;
}


.link-grid label {
  color: #a9a39f;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-right: .2rem;
  font-size: 0.83rem
}

.footer-divider {
    box-sizing: border-box;
    color: rgba(252, 250, 250, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum", "tnum";
    border-top: 1px solid rgba(232, 238, 244, 0.1);
}

.footer-copyright{
  text-align: center;
}
/**
social icons
*/

.social li {
    transition: background ease-in-out 0.4s;
    display: inline-block;
    border-radius: 100%;
    height: 48px;
    width: 48px;
    cursor: pointer;
    background:black;
    text-align: center;
    margin-top: 1rem;
    margin-right: 1.7rem;
    margin-bottom: -1rem;
  }
  .social li:last-child {
    margin-right: 0;
  }
  .social li:hover {
    background:white;
  }
  .social li:hover .fa {
    color:black;
  }
  
  .fa {
    font-size: 24px;
    transition: color ease-in-out 0.4s;
    color: white;
    line-height: 48px;
  }

  .fa-card-quote-left {
    font-size: 0.8rem;
    color: gray;
    height: 16px;
  }

  .fa-card-quote-right {
    font-size: 0.8rem;
    color: gray;
  }

  .account-describle {
    font-size: 1.05rem;
    display: table-cell; 
    vertical-align: middle; 
    text-align: center; 
    color: gray;
    height: 48px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow:ellipsis;
    overflow:hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .fa-weibo {
    color:#D72B2B;
  }

  .fa-linkedin {
      color: #0085AE;
  }

  .fa-twitter {
      color: #32CCFE;
  }

  .fa-facebook {
      color:#3C599F;
  }

  .fa-pinterest {color:#CC2127;} 
  .fa-telegram {color: #51a7e3;}
  .fa-filter {color: #1990FF;}
/*
  .follow-social li {
    transition: ease-in-out 0.4s;
    display: inline-block;
    border-radius: 100%;
    height: 48px;
    width: 48px;
    cursor: pointer;
    background:rgb(0, 0, 0,);
    text-align: center;
    margin-top: 1rem;
  }
  .follow-social li:last-child {
    margin-right: 0;
  }
  .follow-social li:hover {
    background:white;
  }
  .follow-social li:hover .fa {
    color:black;
  }
*/

  .fa-discord-alt {color:#5866F2;}
  .follow-das-social li {
    margin-right: 1.0rem;
    margin-bottom: -1rem;
  }

  .foot-ckbaddr-qr{
    width: 124px;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }

  .fa-clone {
    font-size: 1.0rem;
    transition: color ease-in-out 0.4s;
    color: #ff7a18;
  }

  .fa-check {
    font-size: 1.2rem;
    transition: color ease-in-out 0.4s;
    color: 22C493;
  }

  .fa-registered {
    color: #FFA800;
  }

  .fa-check-circle {
    color: #22C493;
  }

  .fa-calendar {
    color: #22C493;
  }


  .fa-diamond {
    color:purple
  }

  .fa-certificate {
    color: #22C493;
  }

  .fa-usd {
    color: mediumseagreen;
  }

  .fa-lock {
    color:slategray;
  }

  .fa-list-ul {
    color: black;
  }

  .fa-exclamation-circle {
    color:#DF4A46;
  }

  .fa-favorite-sel {
    color: #00DF9B;
    font-size: 1.0rem;
    margin-right: 0.3rem;
    line-height: 1rem;
  }

  .fa-favorite-unsel {
    color: #8894AB;
    font-size: 1.0rem;
    margin-right: .3rem;
    line-height: 1rem;
  }

  .copy-address-a {
    margin-top: 0.5rem;
    font-size: 1rem;
  }

  .dropdown-icon-fa {
    font-size: 1.0rem;
    transition: color ease-in-out 0.4s;
    line-height: 24px;
    margin-right: 0.5rem;
  }

  .foot-link-icon-fa {
    font-size: 1.0rem;
    transition: color ease-in-out 0.4s;
    line-height: 24px;
    margin-right: 0.5rem;
    margin-top: 1.2rem;
  }

  .foot-link-icon-das {
    width: 16px;
    height: 16px;
    display: inline-block;
    background-image: url('https://da.systems/images/ic-registrar-das.svg');
    margin-right: 0.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 0.4rem;
    margin-bottom: -0.17rem;
    margin-left: -0.1rem;
  }
  
  .foot-copy-address {
    font-size: 0.8rem;
    cursor: pointer;
    margin-top: -0.7rem;
  }

  .foot-small-icon-flag {
    font-size: 0.8rem;
    transition: color ease-in-out 0.4s;
    line-height: 24px;
    margin-right: 0.5rem;
  }