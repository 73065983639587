:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #455560;
  --txt-white: #fff;
  --main-color: #349eff;
  --second-color: #62b4ff;
  --box-shadow: rgba(149, 157, 165, 0.9) 0px 8px 24px;
  --main-bg-light: #ffffff;
  --second-bg-light: #fafafb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.9) 0px 8px 24px;
  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.9) 0px 5px 10px;
  --main-color-blue: #349eff;
  --second-color-blue: #62b4ff;
  --main-color-red: #fb0b12;
  --second-color-red: #ff4a6b;
  --main-color-cyan: #10d4d2;
  --second-color-cyan: #2ae9e6;
  --main-color-green: #019707;
  --second-color-green: #4caf50;
  --main-color-orange: #d68102;
  --second-color-orange: #fca11a;
  --sidebar-width: 300px;
  --border-radius: 15px;
  --topnav-height: 60px;
  --topnav-logo-height: 0px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.dropdown {
  position: relative;
  z-index: 99;
}
.dropdown__toggle {
  border: 0;
  outline: 0;
  background-color: transparent;
  position: relative;
}
.dropdown__toggle > i {
  font-size: 2rem;
  color: #455560;
  color: var(--txt-color);
}
.dropdown__toggle-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -12px;
  right: -10px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #349eff;
  background-color: var(--main-color);
  color: #fff;
  color: var(--txt-white);
  font-size: 0.8rem;
}
.dropdown__content {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  width: -webkit-max-content;
  width: max-content;
  max-width: 400px;
  max-height: 486px;
  background-color: #ffffff;
  background-color: var(--main-bg);
  box-shadow: rgba(149, 157, 165, 0.9) 0px 8px 24px;
  box-shadow: var(--box-shadow);
  border-radius: 15px;
  border-radius: var(--border-radius);
  overflow-y: scroll;
  -webkit-transform-origin: top right;
  transform-origin: top right;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: -webkit-transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
}
.dropdown__content.active {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: -webkit-transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: -webkit-transform 0.5s var(--transition-cubic);
  transition: transform 0.5s var(--transition-cubic);
  transition: transform 0.5s var(--transition-cubic), -webkit-transform 0.5s var(--transition-cubic);
}
.dropdown__footer {
  padding: 10px;
  text-align: center;
  background-color: #e6f7ff;
}
